<template>
  <div>
    <!-- Хэдер -->
    <AppHeader class="custom-header" />

    <!-- Основной контент -->
    <div class="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50 py-32 px-4 sm:px-6 lg:px-8">
      <div class="max-w-6xl mx-auto flex flex-col lg:flex-row gap-8">
        <!-- Левая часть (2/3 экрана) -->
        <div class="lg:w-2/3">
          <!-- Логотип команды -->
          <div class="flex justify-center mb-12">
            <img src="../assets/R.png" alt="Team Logo" class="h-56 w-auto transform hover:scale-105 transition-transform duration-300" />
          </div>

          <!-- Заголовок и ссылка на администратора -->
          <div class="text-center mb-12">
            <h1 class="text-5xl font-bold text-gray-800 mb-4 whitespace-nowrap">
              Главный админ сайта — <a href="https://aether-net.ru/profile/86" class="text-blue-600 hover:text-blue-700 transition-colors duration-300" target="_blank" rel="noopener noreferrer">LLlE0</a>
            </h1>
          </div>

          <!-- Контактная информация -->
          <div class="bg-white rounded-lg shadow-lg p-8 mb-12">
            <h2 class="text-3xl font-bold text-gray-800 mb-6">Свяжитесь с нами</h2>
            <ul class="space-y-4">
              <li class="flex items-center">
                <i class="fas fa-envelope text-blue-600 text-xl mr-4"></i>
                <a href="mailto:defersleep@gmail.com" class="text-lg text-gray-600 hover:text-blue-600 transition-colors duration-300">defersleep@gmail.com</a>
              </li>
              <li class="flex items-center">
                <i class="fab fa-telegram text-blue-600 text-xl mr-4"></i>
                <a href="https://t.me/defersleep" class="text-lg text-gray-600 hover:text-blue-600 transition-colors duration-300" target="_blank" rel="noopener noreferrer">По вопросам сотрудничества</a>
              </li>
              <li class="flex items-center">
                <i class="fas fa-users text-blue-600 text-xl mr-4"></i>
                <a href="https://vk.com/defer_sleep" class="text-lg text-gray-600 hover:text-blue-600 transition-colors duration-300" target="_blank" rel="noopener noreferrer">Сообщество команды разработки</a>
              </li>
            </ul>
          </div>

          <!-- Платежные реквизиты -->
          <div class="bg-white rounded-lg shadow-lg p-8 mb-12">
            <h2 class="text-3xl font-bold text-gray-800 mb-6">Поддержать нас</h2>
            <ul class="space-y-4">
              <li class="flex items-center overflow-x-auto">
                <i class="fas fa-credit-card text-blue-600 text-xl mr-4"></i>
                <span class="text-lg text-gray-600">
                  Банковский счет: <span class="font-mono">40817810252222907860</span>
                </span>
              </li>
              <li class="flex items-center overflow-x-auto">
                <i class="fab fa-btc text-blue-600 text-xl mr-4"></i>
                <span class="text-lg text-gray-600">BTC: <span class="font-mono">bc1q6tjejdmmzn3qpt5z5l5xgdamwsmxl9qseywfwz</span></span>
              </li>
              <li class="flex items-center overflow-x-auto">
                <i class="fab fa-ethereum text-blue-600 text-xl mr-4"></i>
                <span class="text-lg text-gray-600">ETH / USDT: <span class="font-mono">0x8d2611adf453a5190f020E9e3F492F48aCA62889</span></span>
              </li>
            </ul>
          </div>
        </div>

        <!-- Правая часть (1/3 экрана) -->
        <div class="lg:w-1/3">
          <!-- Админы -->
          <div class="bg-white rounded-lg shadow-lg p-6 mb-8">
            <h2 class="text-2xl font-bold text-red-600 mb-4">Админы</h2>
            <input
              v-model="adminSearch"
              type="text"
              placeholder="Поиск админов"
              class="w-full p-2 border border-gray-300 rounded-lg mb-4"
            />
            <ul class="space-y-3 max-h-64 overflow-y-auto">
              <li v-for="admin in filteredAdmins" :key="admin.id" class="flex items-center">
                <i class="fas fa-star text-red-600 text-xl mr-4"></i>
                <div>
                  <a
                    :href="`https://aether-net.ru/profile/${admin.id}`"
                    class="text-lg text-red-600 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ admin.username }}
                  </a>
                </div>
              </li>
            </ul>
          </div>

          <!-- Модераторы -->
          <div class="bg-white rounded-lg shadow-lg p-6 mb-8">
            <h2 class="text-2xl font-bold text-green-600 mb-4">Модераторы</h2>
            <input
              v-model="moderatorSearch"
              type="text"
              placeholder="Поиск модераторов"
              class="w-full p-2 border border-gray-300 rounded-lg mb-4"
            />
            <ul class="space-y-3 max-h-64 overflow-y-auto">
              <li v-for="moderator in filteredModerators" :key="moderator.id" class="flex items-center">
                <i class="fas fa-lock text-green-600 text-xl mr-4"></i>
                <div>
                  <a
                    :href="`https://aether-net.ru/profile/${moderator.id}`"
                    class="text-lg text-green-600 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ moderator.username }}
                  </a>
                </div>
              </li>
            </ul>
          </div>

          <!-- Альфа-тестеры -->
          <div class="bg-white rounded-lg shadow-lg p-6 mb-8">
            <h2 class="text-2xl font-bold text-purple-600 mb-4">Альфа-тестеры</h2>
            <input
              v-model="testerSearch"
              type="text"
              placeholder="Поиск тестеров"
              class="w-full p-2 border border-gray-300 rounded-lg mb-4"
            />
            <ul class="space-y-3 max-h-64 overflow-y-auto">
              <li v-for="tester in filteredTesters" :key="tester.id" class="flex items-center">
                <i class="fas fa-seedling text-purple-600 text-xl mr-4"></i>
                <div>
                  <a
                    :href="`https://aether-net.ru/profile/${tester.id}`"
                    class="text-lg text-purple-600 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ tester.username }}
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';

export default {
  components: {
    AppHeader,
  },
  data() {
    return {
      adminSearch: '',
      moderatorSearch: '',
      testerSearch: '',
      admins: [],
      moderators: [],
      testers: [],
    };
  },
  computed: {
    filteredAdmins() {
      return this.admins.filter(admin =>
        admin.username.toLowerCase().includes(this.adminSearch.toLowerCase())
      );
    },
    filteredModerators() {
      return this.moderators.filter(moderator =>
        moderator.username.toLowerCase().includes(this.moderatorSearch.toLowerCase())
      );
    },
    filteredTesters() {
      return this.testers.filter(tester =>
        tester.username.toLowerCase().includes(this.testerSearch.toLowerCase())
      );
    },
  },
  async created() {
    await this.fetchAdmins();
    await this.fetchModerators();
    await this.fetchTesters();
  },
  methods: {
    async fetchAdmins() {
      try {
        const response = await fetch('https://api.aether-net.ru/api/get/all_admins');
        this.admins = await response.json();
      } catch (error) {
        console.error('Ошибка при загрузке админов:', error);
      }
    },
    async fetchModerators() {
      try {
        const response = await fetch('https://api.aether-net.ru/api/get/all_moders');
        this.moderators = await response.json();
      } catch (error) {
        console.error('Ошибка при загрузке модераторов:', error);
      }
    },
    async fetchTesters() {
      try {
        const response = await fetch('https://api.aether-net.ru/api/get/all_alphas');
        this.testers = await response.json();
      } catch (error) {
        console.error('Ошибка при загрузке тестеров:', error);
      }
    },
  },
};
</script>

<style scoped>
.custom-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

/* Адаптивность */
@media (max-width: 1024px) {
  .lg\:w-2\/3 {
    width: 100%;
  }
  .lg\:w-1\/3 {
    width: 100%;
  }

  h1 {
    font-size: 2.5rem;
    white-space: normal;
  }

  .overflow-x-auto {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
</style>