<template>
  <div class="app-container">
    <AppHeader />
    <div class="subscriptions-container">
      <h1 class="page-title">Мои подписки</h1>
      <div v-if="subscriptions.length === 0" class="no-subs">
        <p>У вас нет подписок.</p>
      </div>
      <div v-else class="subscriptions-list">
        <div v-for="subscription in subscriptions" :key="subscription.followee_id" class="subscription-item">
          <img
            v-if="subscription.avatar"
            :src="subscription.avatar"
            alt="Avatar"
            class="subscription-avatar"
          />
          <div v-else class="subscription-avatar placeholder">
            <i class="fa fa-user"></i>
          </div>
          <div class="subscription-info">
            <a
              :href="`https://aether-net.ru/profile/${subscription.followee_id}`"
              class="subscription-name"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ subscription.username || 'Имя не указано' }}
            </a>
          </div>
          <button @click="unsubscribe(subscription.followee_id)" class="unsubscribe-button">
            <span class="button-text">Отписаться</span>
            <i class="fa fa-user-minus button-icon"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';

export default {
  components: {
    AppHeader,
  },
  data() {
    return {
      subscriptions: [],
    };
  },
  async created() {
    await this.fetchSubscriptions();
  },
  methods: {
    async fetchSubscriptions() {
      try {
        const response = await fetch('https://api.aether-net.ru/api/get/subscriptions', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const subscriptions = await response.json();
          await Promise.all(subscriptions.map(sub => this.fetchUserInfo(sub.followee_id)));
        } else {
          console.error('Ошибка при загрузке подписок');
        }
      } catch (error) {
        console.error('Ошибка:', error);
      }
    },
    async fetchUserInfo(followeeId) {
      try {
        const response = await fetch(`https://api.aether-net.ru/api/get/user/${followeeId}`, {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const userInfo = await response.json();
          this.subscriptions.push({
            followee_id: followeeId,
            username: userInfo.username,
            avatar: userInfo.avatar,
          });
        } else {
          console.error(`Ошибка при загрузке информации о пользователе с ID ${followeeId}`);
        }
      } catch (error) {
        console.error('Ошибка:', error);
      }
    },
    async unsubscribe(followeeId) {
      try {
        const response = await fetch(`https://api.aether-net.ru/api/put/unsubscribe/${followeeId}`, {
          method: 'PUT',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          this.subscriptions = this.subscriptions.filter(sub => sub.followee_id !== followeeId);
        } else {
          alert('Ошибка при отписке');
        }
      } catch (error) {
        console.error('Ошибка:', error);
      }
    },
  },
};
</script>

<style scoped>
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--background-color);
}

.subscriptions-container {
  padding: 2rem;
  max-width: 72rem; /* Увеличено с 64rem до 72rem для ПК */
  margin: 0 auto;
  flex: 1;
}

.page-title {
  font-size: 2.5rem; /* Увеличено с 2rem до 2.5rem */
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 2rem; /* Увеличено с 1.5rem до 2rem */
  font-family: 'Exo 2', sans-serif;
}

.subscriptions-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* Увеличено с 1rem до 1.5rem */
}

.subscription-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem; /* Увеличено с 1rem до 1.5rem */
  background: var(--card-background);
  border-radius: 0.75rem; /* Увеличено с 0.5rem до 0.75rem */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Увеличена тень */
  transition: transform 0.2s ease;
}

.subscription-item:hover {
  transform: translateY(-2px);
}

.subscription-avatar {
  width: 5rem; /* Увеличено с 4rem до 5rem */
  height: 5rem;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--accent-color);
}

.subscription-avatar.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary-text-color);
  color: var(--button-text);
  font-size: 2rem; /* Увеличено с 1.5rem до 2rem */
}

.subscription-info {
  flex-grow: 1;
  margin-left: 1.5rem; /* Увеличено с 1rem до 1.5rem */
}

.subscription-name {
  font-size: 1.75rem; /* Увеличено с 1.5rem до 1.75rem */
  font-family: 'Exo 2', sans-serif;
  font-weight: 600;
  color: var(--accent-color);
  text-decoration: none;
}

.subscription-name:hover {
  text-decoration: underline;
}

.unsubscribe-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem; 
  border-radius: 0.25rem;
  cursor: pointer;
  font-family: 'Exo 2', sans-serif;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.unsubscribe-button:hover {
  background-color: #d32f2f;
}

.button-text {
  display: inline;
}

.button-icon {
  display: none;
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {
  .subscriptions-container {
    padding: 0.5rem;
    max-width: 100%;
  }

  .page-title {
    font-size: 1.75rem; /* Уменьшено для мобильных */
    margin-bottom: 1rem;
  }

  .subscriptions-list {
    gap: 1rem;
  }

  .subscription-item {
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .subscription-avatar {
    width: 3rem; /* Уменьшено для мобильных */
    height: 3rem;
  }

  .subscription-avatar.placeholder {
    font-size: 1.5rem;
  }

  .subscription-info {
    margin-left: 1rem;
  }

  .subscription-name {
    font-size: 1.25rem; /* Уменьшено для мобильных */
  }

  .unsubscribe-button {
    padding: 0.5rem;
    font-size: 1rem;
  }

  .button-text {
    display: none; /* Скрываем текст на мобильных */
  }

  .button-icon {
    display: inline; /* Показываем иконку на мобильных */
  }
}

.no-subs {
  text-align: center;
  padding: 2rem;
  background: var(--card-background);
  border-radius: 0.5rem;
  color: var(--text-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 1.2rem;
}
</style>