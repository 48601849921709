<template>
  <div class="comments-section" :class="{ 'mobile-view': isMobile }">
    <div class="inner-wrapper" v-if="description">
      <div class="description-container" :class="{ 'expanded': shouldShowFullDescription }">
        <h3 class="title">{{ postTitle }}</h3>
        <div class="description">
          <p>{{ postDescription }}</p>
        </div>
        <button
          v-if="shouldShowToggleDescription && !shouldShowFullDescriptionByDefault"
          @click="toggleDescription"
          class="read-more text-blue-500 mt-1"
        >
          <span>{{ isDescriptionExpanded ? 'Свернуть' : 'Читать далее' }}</span>
          <i :class="isDescriptionExpanded ? 'fa fa-arrow-up' : 'fa fa-arrow-down'" class="ml-1"></i>
        </button>
      </div>

      <div v-if="tags && tags.length > 0" class="post-tags mt-2">
        <span v-for="(tag, index) in visibleTags" :key="index" class="post-tag">
          {{ tag }}
        </span>
        <button v-if="tags.length > 5" @click="toggleTags" class="text-blue-500 text-sm">
          {{ isTagsExpanded ? 'Свернуть' : 'Развернуть' }}
        </button>
      </div>
    </div>

    <hr class="divider my-4" />

    <div class="inner-wrapper">
      <h3 class="text-lg mb-1">Комментарии</h3>
      <form @submit.prevent="addComment" class="mt-2">
        <textarea
          v-model="newComment"
          placeholder="Напишите комментарий..."
          class="w-full p-2 border border-gray-300 rounded"
          rows="3"
        ></textarea>
        <button type="submit" class="mt-2 bg-blue-500 text-white py-1 px-2 rounded text-sm">Добавить комментарий</button>
      </form>
      <div class="comments-list mt-2">
        <div v-if="comments.length > 0">
          <div v-for="comment in comments" :key="comment.id" class="comment-item relative">
            <CommentItem
              :comment="comment"
              :currentUserId="currentUserId"
              @delete-comment="handleDeleteComment"
            />
            <button
              v-if="comment.user_id !== currentUserId && comment.user_id == currentUserId"
              @click="handleDeleteComment(comment.id)"
              class="delete-comment-btn absolute top-2 right-2 text-gray-700 hover:text-gray-900"
            >
            </button>
          </div>
        </div>
        <div v-else>
          <p>Нет комментариев.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommentItem from '@/components/CommentItem.vue';

export default {
  props: {
    postId: { type: String, required: true },
    description: { type: String, default: '' },
    tags: { type: Array, default: () => [] },
    currentUserId: { type: String, default: null },
    isMobile: { type: Boolean, default: false },
  },
  data() {
    return {
      comments: [],
      newComment: '',
      isDescriptionExpanded: false,
      isTagsExpanded: false,
    };
  },
  computed: {
    postTitle() {
      const desc = this.description || '';
      const title = desc.split('\n')[0] || desc;
      console.log('postTitle:', title);
      return title;
    },
    postDescription() {
      const desc = this.description || '';
      const description = desc.includes('\n') ? desc.split('\n').slice(1).join('\n') : '';
      console.log('postDescription:', description);
      return description;
    },
    visibleTags() {
      const visible = this.isTagsExpanded ? this.tags : this.tags.slice(0, 5);
      console.log('visibleTags:', visible, 'isTagsExpanded:', this.isTagsExpanded, 'total tags:', this.tags.length);
      return visible;
    },
    shouldShowToggleDescription() {
      const desc = this.postDescription || '';
      const lines = desc.split('\n').length;
      const length = desc.length;
      const shouldShow = length > 140 || lines > 3;
      console.log('shouldShowToggleDescription:', shouldShow, 'description length:', length, 'lines:', lines);
      return shouldShow;
    },
    shouldShowFullDescriptionByDefault() {
      const desc = this.postDescription || '';
      const lines = desc.split('\n').length;
      const length = desc.length;
      const shouldShow = length <= 140 && lines <= 3;
      console.log('shouldShowFullDescriptionByDefault:', shouldShow, 'description length:', length, 'lines:', lines);
      return shouldShow;
    },
    shouldShowFullDescription() {
      const shouldShow = this.shouldShowFullDescriptionByDefault || this.isDescriptionExpanded;
      console.log('shouldShowFullDescription:', shouldShow, 'isDescriptionExpanded:', this.isDescriptionExpanded);
      return shouldShow;
    },
  },
  methods: {
    async loadComments() {
      try {
        const response = await fetch(`https://api.aether-net.ru/api/get/comments/${this.postId}`);
        const data = await response.json();
        this.comments = Array.isArray(data) ? data : [];
        console.log('loadComments:', this.comments);
      } catch (error) {
        console.error('Ошибка при загрузке комментариев:', error);
        this.comments = [];
      }
    },
    async addComment() {
      if (!this.newComment.trim()) return;

      try {
        const response = await fetch(`https://api.aether-net.ru/api/create/comment/${this.postId}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ content: this.newComment }),
          credentials: 'include',
        });
        if (response.ok) {
          this.loadComments();
          this.newComment = '';
          console.log('addComment: Comment added successfully');
        } else {
          const err = await response.json();
          alert(`Ошибка: ${err.error}`);
          console.error('addComment error:', err);
        }
      } catch (error) {
        console.error('Ошибка:', error);
        alert('Ошибка при добавлении комментария.');
      }
    },
    handleDeleteComment(commentId) {
      const commentToDelete = this.comments.find(c => c.id == commentId);
      this.comments = this.comments.filter(c => c.id !== commentId);

      fetch(`https://api.aether-net.ru/api/delete/comment/${commentId}`, {
        method: 'DELETE',
        credentials: 'include',
      })
        .then(response => {
          if (!response.ok) {
            this.comments.push(commentToDelete);
            alert('Ошибка при удалении комментария.');
            console.error('handleDeleteComment error: Failed to delete comment on server');
          } else {
            console.log('handleDeleteComment: Comment deleted successfully', commentId);
          }
        })
        .catch(error => console.error('handleDeleteComment error:', error));
    },
    toggleDescription() {
      console.log('toggleDescription: Before toggle, isDescriptionExpanded:', this.isDescriptionExpanded);
      this.isDescriptionExpanded = !this.isDescriptionExpanded;
      console.log('toggleDescription: After toggle, isDescriptionExpanded:', this.isDescriptionExpanded);
    },
    toggleTags() {
      console.log('toggleTags: Before toggle, isTagsExpanded:', this.isTagsExpanded);
      this.isTagsExpanded = !this.isTagsExpanded;
      console.log('toggleTags: After toggle, isTagsExpanded:', this.isTagsExpanded);
    },
  },
  mounted() {
    this.loadComments();
    console.log('mounted: Initial state', {
      description: this.description,
      tags: this.tags,
      isDescriptionExpanded: this.isDescriptionExpanded,
      isTagsExpanded: this.isTagsExpanded,
    });
  },
  components: { CommentItem },
};
</script>

<style scoped>
.comments-section {
  width: 34%;
  padding: 0;
  background-color: var(--background-color);
  border-left: 1px solid var(--secondary-text-color);
  color: var(--text-color);
  max-height: 100%;
  overflow-y: auto;
}

.inner-wrapper {
  padding: 16px;
}

.mobile-view {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  z-index: 100;
  background-color: var(--background-color);
  border-top: 1px solid var(--secondary-text-color);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .comments-section {
    width: 100%;
  }
}

.title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
  color: var(--text-color);
}

.description-container {
  position: relative;
}

.description {
  max-height: 6rem; /* Ограничиваем высоту для свёрнутого состояния */
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.description p {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-size: 1em;
  line-height: 1.5;
  color: var(--text-color);
  margin: 0;
}

.description-container.expanded .description {
  max-height: none; /* Раскрываем полностью */
}

.read-more {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: var(--accent-color);
  cursor: pointer;
}

.read-more:hover {
  color: var(--button-bg);
}

.post-tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.post-tag {
  background-color: var(--accent-color);
  color: var(--button-text);
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.9em;
}

.divider {
  border: 0;
  border-top: 1px solid var(--secondary-text-color);
}

textarea {
  border: 1px solid var(--secondary-text-color);
  border-radius: 4px;
  resize: none;
  width: 100%;
  padding: 10px;
  background: var(--card-background);
  color: var(--text-color);
}

textarea:focus {
  outline: none;
  border-color: var(--accent-color);
}

button[type="submit"] {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 12px;
}

button[type="submit"]:hover {
  background-color: var(--accent-color);
}

.comments-list {
  margin-top: 1rem;
}

.comment-item {
  position: relative;
  margin-bottom: 1rem;
}

.delete-comment-btn {
  font-size: 1rem;
  cursor: pointer;
  color: var(--text-color);
}
</style>