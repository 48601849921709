<template>

<div class="apph">
  <router-view />
</div>
</template>

<style>
:root {
  --background-color: #e7f3ff;
  --text-color: #333;
  --card-background: #ffffff;
  --card-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --accent-color: #2597ff;
  --secondary-text-color: #666;
  --header-bg: #e7f3ff;
  --button-bg: #2597ff;
  --button-text: #ffffff;
}

[data-theme="dark"] {
  --background-color: #1a2a44;
  --text-color: #e7f3ff;
  --card-background: #2c3e50;
  --card-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  --accent-color: #4da8ff;
  --secondary-text-color: #a0aec0;
  --header-bg: #2c3e50;
  --button-bg: #4da8ff;
  --button-text: #e7f3ff;

}

.apph {
  background-color: var(--background-color);
  z-index: -40;
}
</style>