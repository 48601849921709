<template>
  <div class="post-widget" :style="{ backgroundImage: `url(${post.image_urls[0]})`, height: postHeight + 'px' }">
    <div class="post-overlay"></div>
    <div class="post-info">
      <div class="post-header">
        <img :src="post.avatar" alt="Author Avatar" class="post-avatar" />
        <div class="post-author-info">
          <router-link :to="`/profile/${post.user_id}`" class="post-author-name">{{ post.username }}</router-link>
          <div class="post-stats">
            <i class="fa fa-thumbs-up"></i> {{ formatNumber(post.likes) }} |
            <i class="fa fa-eye"></i> {{ formatNumber(post.views) }}
          </div>
        </div>
      </div>
      <h2 class="post-description">{{ post.description }}</h2>
      <div v-if="post.tags && post.tags.length > 0 && post.tags[0]" class="post-tags">
        <span v-for="(tag, index) in post.tags.slice(0, 2)" :key="index" class="post-tag">
          {{ tag }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      postHeight: 200, // Начальная высота, будет обновлена после загрузки изображения
    };
  },
  mounted() {
    this.calculatePostHeight();
  },
  methods: {
    formatNumber(number) {
      if (number >= 1000) {
        return (number / 1000).toFixed(1) + 'K';
      }
      return number;
    },
    calculatePostHeight() {
      const img = new Image();
      img.src = this.post.image_urls[0];
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        const containerWidth = this.$el.offsetWidth; // Ширина контейнера поста
        let calculatedHeight = containerWidth / aspectRatio;

        // Ограничиваем высоту: минимум 200px (чтобы описание влезло), максимум 400px (чтобы не было слишком высоким)
        calculatedHeight = Math.max(200, Math.min(400, calculatedHeight));
        this.postHeight = calculatedHeight;
      };
    },
  },
};
</script>

<style scoped>
.post-widget {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  overflow: hidden;
}

.post-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.7)
  );
  z-index: 1;
}

.post-info {
  position: relative;
  z-index: 2;
  padding: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #fff;
}

.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.post-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 12px;
  border: 2px solid #fff;
}

.post-author-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.post-author-name {
  font-size: 1em;
  color: #fff;
  font-weight: 600;
  margin-bottom: 4px;
  text-decoration: none;
}

.post-author-name:hover {
  text-decoration: underline;
}

.post-stats {
  display: flex;
  align-items: center;
  font-size: 0.85em;
  color: #e0e0e0;
}

.post-stats i {
  margin-right: 6px;
}

.post-description {
  font-size: 1.1em;
  font-weight: 700;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.post-tags {
  display: flex;
  gap: 6px;
  margin-top: 8px;
}

.post-tag {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.85em;
  color: #fff;
}
</style>