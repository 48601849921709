<template>
  <div class="menu-widget" v-if="isOpen">
    <!-- Затемняющий оверлей -->
    <div class="menu-overlay" @click="closeMenu"></div>
    <!-- Панель меню -->
    <div class="menu-panel">
      <!-- Заголовок и кнопка закрытия -->
      <div class="menu-header">
        <h2 class="menu-title">Меню</h2>
        <button class="close-btn" @click="closeMenu">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <!-- Навигация -->
      <nav class="menu-nav">
        <ul>
          <li>
            <a href="#" @click.prevent="goToProfile">
              <i class="fas fa-user"></i>
              <span>Профиль</span>
            </a>
          </li>
          <li>
            <router-link to="/subscriptions" @click="closeMenu">
              <i class="fas fa-bell"></i>
              <span>Подписки</span>
            </router-link>
          </li>
          <li>
            <router-link to="/support" @click="closeMenu">
              <i class="fas fa-question-circle"></i>
              <span>Поддержка</span>
            </router-link>
          </li>
          <li>
            <router-link to="/terms" @click="closeMenu">
              <i class="fas fa-file-alt"></i>
              <span>Правила</span>
            </router-link>
          </li>
          <li class="logout-item">
            <a href="#" @click.prevent="logout">
              <i class="fas fa-sign-out-alt"></i>
              <span>Выйти</span>
            </a>
          </li>
        </ul>
      </nav>
      <!-- Переключатель темы -->
      <div class="theme-switcher">
        <label class="switch" for="theme-toggle">
          <input
            type="checkbox"
            id="theme-toggle"
            v-model="isDarkTheme"
          />
          <span class="slider round"></span>
        </label>
        <span class="theme-label">{{ currentTheme === 'light' ? 'Светлая' : 'Темная' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentTheme: localStorage.getItem('theme') || 'light',
    };
  },
  computed: {
    isDarkTheme: {
      get() {
        return this.currentTheme === 'dark';
      },
      set(value) {
        this.currentTheme = value ? 'dark' : 'light';
        localStorage.setItem('theme', this.currentTheme);
        document.documentElement.setAttribute('data-theme', this.currentTheme);
        console.log('Тема переключена на:', this.currentTheme); // Для отладки
      },
    },
  },
  methods: {
    closeMenu() {
      this.$emit('close');
    },
    goToProfile() {
      if (this.userId) {
        this.$router.push(`/profile/${this.userId}`).then(() => {
          window.location.reload();
        });
        this.closeMenu();
      }
    },
    logout() {
      fetch("https://api.aether-net.ru/logout", {
        method: 'DELETE',
        credentials: 'include',
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Ошибка при выходе');
          }
          document.cookie.split(";").forEach((c) => {
            const cookieName = c.split("=")[0].trim();
            document.cookie = `${cookieName}=; path=/; domain=${window.location.hostname}; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
          });
          this.$router.push('/auth');
          this.closeMenu();
        })
        .catch(error => {
          console.error("Ошибка при выходе:", error);
        });
    },
  },
  mounted() {
    document.documentElement.setAttribute('data-theme', this.currentTheme);
  },
};
</script>

<style scoped>
/* Контейнер меню */
.menu-widget {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

/* Затемняющий оверлей */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  animation: fadeIn 0.3s ease forwards;
  z-index: 1000; /* Оверлей должен быть ниже панели */
}

/* Панель меню */
.menu-panel {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: var(--card-background);
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  z-index: 1001; /* Панель должна быть выше оверлея */
  animation: slideIn 0.3s ease forwards;
}

/* Анимация появления оверлея */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Анимация появления панели */
@keyframes slideIn {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

/* Заголовок и кнопка закрытия */
.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid var(--secondary-text-color);
}

.menu-title {
  font-size: 2rem;
  font-family: 'Exo 2', sans-serif;
  color: var(--accent-color);
  margin: 0;
}

.close-btn {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 5px;
}

/* Навигация */
.menu-nav {
  flex: 1;
}

.menu-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-nav ul li {
  border-bottom: 1px solid var(--secondary-text-color);
}

.menu-nav ul li a {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.2rem;
  font-family: 'Exo 2', sans-serif;
}

.menu-nav ul li a i {
  margin-right: 10px;
  font-size: 1.2rem;
}

.menu-nav ul li a:hover {
  background-color: var(--background-color);
}

.logout-item {
  margin-top: auto;
}

/* Переключатель темы */
.theme-switcher {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-top: 1px solid var(--secondary-text-color);
  z-index: 1002; /* Убедимся, что слайдер кликабелен */
}

.theme-label {
  margin-left: 10px;
  color: var(--text-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 1rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--secondary-text-color);
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: var(--card-background);
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--accent-color);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Адаптивность */
@media (max-width: 768px) {
  .menu-panel {
    width: 80%;
  }

  .menu-title {
    font-size: 1.5rem;
  }

  .menu-nav ul li a {
    font-size: 1rem;
  }
}
</style>