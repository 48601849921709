import { createRouter, createWebHistory } from 'vue-router';
import Auth from '@/views/AuthView.vue';
import Home from '@/views/HomeView.vue';
import Profile from '@/views/ProfileView.vue';
import CreatePost from '@/components/CreatePost.vue';
import PostWidget from '@/components/PostWidget.vue';
import UnderDevelopmentView from '@/views/UnderDevelopmentView.vue';
import TermsView from '@/views/TermsView.vue';
import HelpView from '@/views/HelpView.vue';
import SubscriptionsView from '@/views/SubscriptionsView.vue';

const isAuthenticated = () => {
  return document.cookie.includes('auth'); 
};


const getUserIdFromCookie = () => {
  const cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith('userID='));
  return cookie ? cookie.split('=')[1] : null;
};

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/auth',
    component: Auth,
  },
  {
    path: '/profile/:profileID',
    component: Profile,
    beforeEnter: (to, from, next) => {
      const userId = getUserIdFromCookie(); 

       if (!isAuthenticated()) {
        next('/auth');
        return;
      }

      if (to.params.profileID === 'me') {
        if (userId) {
          next(`/profile/${userId}`); 
        } else {
          next('/auth');
        }
      } else {
        next(); 
      }
    },
  },
  {
    path: '/create-post',
    component: CreatePost,
    beforeEnter: (to, from, next) => {
      if (!isAuthenticated()) {
        next('/auth'); 
      } else {
        next();
      }
    },
  },
  {
    path: '/watch-post/:postID',
    component: PostWidget,
    beforeEnter: (to, from, next) => {
      if (!isAuthenticated()) {
        next('/auth'); 
      } else {
        next();
      }
    },
  },
  {
    path: '/subscriptions',
    component: SubscriptionsView,
    beforeEnter: (to, from, next) => {
      if (!isAuthenticated()) {
        next('/auth'); 
      } else {
        next();
      }
    },
  },
  {
    path: '/support',
    component: HelpView,
  },
  {
    path: '/terms',
    component: TermsView,
  },
  {
    path: '/:catchAll(.*)',
    component: UnderDevelopmentView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;