<template>
  <div class="post-detail-widget fixed inset-0 flex items-center justify-center z-50">
    <div class="modal-overlay w-full h-full" @click="close"></div>
    <div class="modal-container w-11/12 md:max-w-4xl mx-auto rounded shadow-lg overflow-y-auto flex flex-col md:flex-row">
      <button @click="close" class="close-button absolute top-2 right-2 text-gray-500 hover:text-gray-700">
        <i class="fa fa-times"></i>
      </button>
      <div class="modal-content py-4 text-left px-6 w-full md:w-2/3 flex flex-col relative">
        <div class="flex justify-between items-center">
          <button v-if="isCurrentUser" @click="editPost" class="edit-button text-gray-500 hover:text-gray-700">
            <span>Изменить <i class="fa fa-pencil"></i></span>
          </button>
        </div>

        <div class="image-container mt-2">
          <swiper ref="swiper" :options="swiperOptions" @init="onSwiperInit" @slideChange="onSlideChange">
            <swiper-slide v-for="(url, index) in localPost.image_urls" :key="index">
              <div class="image-wrapper relative flex">
                <div class="image-background absolute inset-0 bg-cover bg-center filter blur-lg z-0" :style="{ backgroundImage: `url(${url})` }"></div>
                <img
                  :src="url"
                  alt="Post Image"
                  class="compressed-image cursor-pointer w-full h-full object-contain"
                  :class="{ 'blur': localPost.is_nsfw && !imageClicked[index] }"
                  @click="handleImageClick(index)"
                />
              </div>
            </swiper-slide>
            <template v-slot:pagination>
              <div class="swiper-pagination"></div>
            </template>
            <template v-slot:button-prev>
              <div class="swiper-button-prev"></div>
            </template>
            <template v-slot:button-next>
              <div class="swiper-button-next"></div>
            </template>
          </swiper>
        </div>

        <div class="user-info mt-2 flex items-center justify-between">
          <div class="flex items-center">
            <img :src="localPost.avatar" alt="Avatar" class="avatar rounded-full w-8 h-8" />
            <p class="ml-2 text-sm">
              <strong>Автор: </strong>
              <a :href="`/profile/${localPost.user_id}`" class="text-blue-500 hover:underline">{{ localPost.username }}</a>
            </p>
          </div>
          <div class="flex items-center">
            <div class="post-stats flex items-center pr-2">
              <i class="fa fa-eye mr-1" style="font-size:1rem"></i>
              <span class="text-sm">{{ localPost.views }}</span>
            </div>
            <button
              @click="toggleLike"
              class="like-button flex items-center text-sm"
              :class="localPost.is_liked ? 'liked' : 'unliked'"
            >
              <i class="fa fa-thumbs-up mr-1"></i>
              <span>{{ localPost.likes }}</span>
            </button>
          </div>
        </div>

        <button
          v-if="getUserRoleFromCookie() == '2'"
          @click="deletePost"
          class="ml-4 bg-red-600 text-white py-1 px-2 rounded text-sm"
        >
          Удалить пост
        </button>
        <button
          v-else-if="getUserRoleFromCookie() == '1'"
          @click="reportPost"
          class="ml-4 bg-yellow-500 text-white py-1 px-2 rounded text-sm"
        >
          Пожаловаться
        </button>

        <EditPostWidget
          v-if="showEditPostWidget"
          :post="localPost"
          @close="showEditPostWidget = false"
          @update="updatePost"
          @delete="deletePost"
        />
      </div>

      <CommentsWidget
        :postId="localPost.id.toString()"
        :description="localPost.description"
        :tags="localPost.tags"
        :currentUserId="getUserIdFromCookie()"
        :isMobile="false"
        class="w-full md:w-1/3"
        style="height: auto; flex-basis:auto"
      />
    </div>

    <div v-if="selectedImage" class="image-modal fixed inset-0 flex items-center justify-center z-60" @click="closeModal">
      <div class="modal-overlay" @click="closeModal"></div>
      <div class="modal-content bg-white rounded shadow-lg p-4" @click.stop>
        <img :src="selectedImage" alt="Увеличенное изображение" class="max-w-full h-auto" />
        <div class="flex justify-between mt-4">
          <button @click="closeModal" class="mt-4 bg-red-600 text-white py-1 px-2 rounded text-sm">Закрыть</button>
          <a :href="selectedImage" download class="mt-4 bg-green-600 text-white py-1 px-2 rounded text-sm">Скачать изображение</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
import EditPostWidget from '@/components/EditPostWidget.vue';
import CommentsWidget from '@/components/CommentsWidget.vue';

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      selectedImage: null,
      localPost: { ...this.post },
      showEditPostWidget: false,
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        loop: true,
        centeredSlides: true,
      },
      imageClicked: [],
    };
  },
  computed: {
    isCurrentUser() {
      const userId = this.getUserIdFromCookie();
      return userId == this.localPost.user_id;
    },
  },
  methods: {
    getTitle(description) {
      const firstNewLineIndex = description.indexOf('\n');
      return firstNewLineIndex !== -1 ? description.substring(0, firstNewLineIndex) : description;
    },
    close() {
      this.$emit('close');
    },
    openModal(url) {
      this.selectedImage = url;
      this.showModal = true;
    },
    closeModal() {
      this.selectedImage = null;
      this.showModal = false;
    },
    toggleLike() {
      const currentLikedState = this.localPost.is_liked;
      const currentLikesCount = this.localPost.likes;

      this.localPost.is_liked = !currentLikedState;
      this.localPost.likes += this.localPost.is_liked ? 1 : -1;

      localStorage.setItem(`post_${this.localPost.id}_liked`, this.localPost.is_liked);

      const endpoint = this.localPost.is_liked
        ? `https://api.aether-net.ru/api/put/like/${this.localPost.id}`
        : `https://api.aether-net.ru/api/put/unlike/${this.localPost.id}`;

      fetch(endpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      })
        .then(response => {
          if (!response.ok) {
            this.localPost.is_liked = currentLikedState;
            this.localPost.likes = currentLikesCount;
            alert('Ошибка при изменении лайка. Попробуйте еще раз.');
          }
        })
        .catch(error => {
          this.localPost.is_liked = currentLikedState;
          this.localPost.likes = currentLikesCount;
          console.error('Ошибка при изменении лайка:', error);
        });
    },
    incrementViewCount() {
      this.localPost.views += 1;
    },
    editPost() {
      this.showEditPostWidget = true;
    },
    updatePost(updatedPost) {
      this.localPost = updatedPost;
      this.showEditPostWidget = false;
    },
    deletePost() {
      const endpoint = `https://api.aether-net.ru/api/delete/sudo_post/${this.localPost.id}`;
      fetch(endpoint, {
        method: 'DELETE',
        credentials: 'include',
      })
        .then(response => {
          if (response.ok) {
            this.close();
          } else {
            alert('Ошибка при удалении поста');
          }
        })
        .catch(error => {
          console.error('Ошибка при удалении поста:', error);
        });
    },
    reportPost() {
      const endpoint = `https://api.aether-net.ru/api/create/warning/${this.localPost.id}`;
      fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      })
        .then(response => {
          if (response.ok) {
            alert('Жалоба отправлена');
          } else {
            alert('Ошибка при отправке жалобы');
          }
        })
        .catch(error => {
          console.error('Ошибка при отправке жалобы:', error);
        });
    },
    getUserIdFromCookie() {
      const name = "userID=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(';');
      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) == 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
      return null;
    },
    getUserRoleFromCookie() {
      const name = "role=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(';');
      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) == 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
      return null;
    },
    handleImageClick(index) {
      this.imageClicked[index] = true;
      this.openModal(this.localPost.image_urls[index]);
    },
    onSwiperInit(swiper) {
      this.adjustSlideHeight(swiper);
    },
    onSlideChange(swiper) {
      this.adjustSlideHeight(swiper);
    },
    adjustSlideHeight(swiper) {
      const slides = swiper.slides;
      let maxHeight = 0;

      slides.forEach(slide => {
        const slideHeight = slide.scrollHeight;
        if (slideHeight > maxHeight) {
          maxHeight = slideHeight;
        }
      });

      slides.forEach(slide => {
        slide.style.height = `${maxHeight}px`;
      });
    },
  },
  mounted() {
    const likedState = localStorage.getItem(`post_${this.localPost.id}_liked`);
    if (likedState !== null) {
      this.localPost.is_liked = likedState === 'true';
    }
  },
  components: {
    EditPostWidget,
    CommentsWidget,
    Swiper,
    SwiperSlide,
  },
};
</script>

<style>
.post-detail-widget {
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.5);
}

.modal-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
}

.modal-container {
  position: relative;
  max-width: 80%;
  max-height: 93%;
  margin: auto;
  background: var(--card-background);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 80%;
  color: var(--text-color);
}

@media (min-width: 768px) {
  .modal-container {
    flex-direction: row;
  }
}

.compressed-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info p {
  margin-left: 10px;
}

.like-button {
  background-color: transparent;
  border: none;
  outline: none;
  width: auto;
  height: auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.like-button i {
  font-size: 24px;
}

.like-button.liked {
  color: var(--accent-color);
}

.like-button.unliked {
  color: var(--secondary-text-color);
}

button {
  transition: background-color 0.3s, color 0.3s;
}

button:hover {
  background-color: var(--accent-color);
  color: var(--button-text);
}

.image-grid img {
  transition: transform 0.2s;
}

.image-grid img:hover {
  transform: scale(1.05);
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 60;
}

.modal-content {
  background: var(--card-background);
  border-radius: 8px;
  padding: 20px;
  overflow: auto;
}

.modal-content img {
  max-width: 100%;
  max-height: 80vh;
}

.post-stats {
  display: flex;
  align-items: center;
  margin-right: 0rem;
  justify-content: space-between;
}

.close-button {
  font-size: 1.7rem;
  cursor: pointer;
  width: 1.7rem;
  height: 1.7rem;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: var(--text-color);
}

.edit-button {
  font-size: 1.2rem;
  cursor: pointer;
  height: 100%;
  padding: 0 0.5rem;
  background-color: var(--button-bg);
  color: var(--button-text);
  border: 1px solid var(--text-color);
  text-align: center;
  text-shadow: 1px 1px 0 var(--secondary-text-color);
  display: inline-block;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  filter: blur(10px);
  display: block;
  z-index: -1;
}
</style>