<template>
  <div>
    <header class="header">
      <router-link to="/" class="aether">Aether</router-link>
      <button v-if="isLoggedIn" @click="toggleSidebar" class="menu-button">
        <span class="menu-text">Меню</span>
        <i class="fas fa-bars"></i>
      </button>
      <button v-else @click="redirectToAuth" class="menu-button">
        <span class="menu-text">Войти</span>
        <i class="fas fa-sign-in-alt ml-2"></i>
      </button>
    </header>

    <MenuWidget :is-open="sidebarOpen" :user-id="userId" @close="toggleSidebar" />
  </div>
</template>

<script>
import MenuWidget from '@/components/MenuWidget.vue';

export default {
  components: {
    MenuWidget,
  },
  data() {
    return {
      sidebarOpen: false,
      userId: this.getUserIdFromCookie(),
      auth: this.getAuthFromCookie(),
    };
  },
  computed: {
    isLoggedIn() {
      return this.userId != null && this.auth != null;
    },
  },
  methods: {
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    getUserIdFromCookie() {
      const name = "userID=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(';');
      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) == 0) {
          const cookieValue = cookie.substring(name.length, cookie.length);
          return this.isCookieValid(cookie) ? cookieValue : null;
        }
      }
      return null;
    },
    getAuthFromCookie() {
      const name = "auth=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(';');
      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) == 0) {
          const cookieValue = cookie.substring(name.length, cookie.length);
          return this.isCookieValid(cookie) ? cookieValue : null;
        }
      }
      return null;
    },
    isCookieValid(cookie) {
      const cookieParts = cookie.split(';');
      for (let part of cookieParts) {
        if (part.trim().startsWith('expires=')) {
          const expires = new Date(part.trim().substring(8));
          return expires > new Date();
        }
      }
      return true;
    },
    redirectToAuth() {
      this.$router.push('/auth');
    },
  },
  mounted() {
    if (window.innerWidth > 768) {
      this.sidebarOpen = false;
    }
  },
};
</script>

<style>
/* Убираем scoped, чтобы стили могли использовать глобальные переменные */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background-color: var(--header-bg);
  color: var(--text-color);
  margin-bottom: 16px;
}

.aether {
  font-size: 4rem;
  color: var(--accent-color);
  font-family: 'Varela Round', sans-serif;
  text-decoration: none;
}

.aether:hover {
  text-decoration: underline;
}

.menu-button {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  background-color: var(--button-bg); /* Постоянный цвет кнопки */
  color: var(--button-text);
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: filter 0.3s ease; /* Плавное затемнение */
}

.menu-button:hover {
  filter: brightness(0.9); /* Чуть темнее при наведении */
}

.menu-button i {
  margin-left: 8px;
  font-size: 1.2rem;
}

.menu-text {
  font-family: 'Exo 2', sans-serif;
}

/* Адаптивность */
@media (max-width: 768px) {
  .aether {
    font-size: 2.5rem;
  }

  .menu-text {
    display: none;
  }

  .menu-button {
    padding: 10px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    justify-content: center;
  }

  .menu-button i {
    margin-left: 0;
    font-size: 1.5rem;
  }
}
</style>